@import 'abstracts/spacers';
@import 'abstracts/helpers';
@import 'Animation/Bounce.module';

.custom-pricing {
  box-shadow: none !important;

  .panel-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 15px;
    width: 390px;

    .company {
      font-weight: 700;
    }

    .email {
      font-size: @font-size-small;
      font-weight: 600;
    }

    button {
      display: flex;
      border: none;
      padding-left: 0;
      text-align: left;
      cursor: pointer;
    }

    b {
      color: black;
      width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
    }
  }

  .trash-arrow-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: @spacer;
    margin-left: @spacer * 2;

    .pannel-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.custom-discount-wrapper {
  box-shadow: none !important;
}

:global(.rs-panel-header)[aria-expanded='false'] .trash-arrow-icons {
  display: none;
}

// To align the NameTag in the product column with discount info
.product-column-discount-tag {
  margin-top: 2px;
  padding-left: 1px;
}

.divider-style {
  margin: 0;
  padding: 0;
}

.add-new-discount {
  margin: 0;
  padding: 0;
  font-weight: 600;

  &:hover {
    text-decoration: none;
  }
}

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
}

.name-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: @primary-darker;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  font-weight: 600;
  color: white;
  font-size: @font-size-small;

  span {
    position: absolute;
    top: 54%;
    left: 54%;
    transform: translate(-50%, -54%);
  }
}

.hide-tooltip {
  display: none;
}

.panel-price-wrapper {
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;

  .discount-tag {
    margin: 0;
    font-size: 10px; // Avoiding wrapping earlier
  }
}
