@import '../abstracts/spacers';
@import '../abstracts/helpers';

.contract-name-tag {
  gap: (@spacer/2);
  width: max-content;
  border: 1px solid @primary-darker;
  border-radius: 15px;
  padding: 0 @spacer;
  height: 25px;
  background-color: @teal-mid;

  .contract-price {
    font-size: @font-size-small;
    font-weight: 600;
    color: @primary-darker;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
